import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button as AntButton, Checkbox, Col, DatePicker, Form, Input, Row, Select, Spin, Tabs, Tag, Typography, Upload } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getGroupes } from 'app/entities/groupe/groupe.reducer';
import { getEntities as getEleves } from 'app/entities/eleve/eleve.reducer';
import { createEntity, getDevoir, reset, updateEntity } from './devoir.reducer';
import moment from 'moment';
import { ArrowLeftOutlined, LoadingOutlined, PlusOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { mapIdList } from 'app/shared/util/entity-utils';
import { IProf } from 'app/shared/model/prof.model';
import { getEntities as getProfs } from 'app/entities/prof/prof.reducer';

const { TabPane } = Tabs;
const { Title } = Typography;

export const DevoirUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const groupes = useAppSelector(state => state.groupe.entities);
  const eleves = useAppSelector(state => state.eleve.entities);
  const devoirEntity = useAppSelector(state => state.devoir.entity);
  const loading = useAppSelector(state => state.devoir.loading);
  const updating = useAppSelector(state => state.devoir.updating);
  const updateSuccess = useAppSelector(state => state.devoir.updateSuccess);
  const profs = useAppSelector(state => state.prof.entities) as IProf[];
  const [selectedGroupe, setSelectedGroupe] = useState<number[]>([]);
  const [selectedEleve, setSelectedEleve] = useState<number[]>([]);
  const [isEleveMode, setIsEleveMode] = useState(true);
  const [fileData, setFileData] = useState<string | undefined>();
  const [fileContentType, setFileContentType] = useState<string | undefined>();

  const handleClose = () => {
    navigate('/devoir' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getDevoir(id));
    }
    dispatch(getGroupes({}));
    dispatch(getEleves({}));
    dispatch(getProfs({}));
  }, [dispatch, id, isNew]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (devoirEntity && !isNew) {
      setFileContentType(devoirEntity.fichierContentType);
      setFileData(devoirEntity.fichier);
    }
  }, [devoirEntity, isNew]);

  const saveEntity = values => {
    const entity = {
      ...devoirEntity,
      ...values,
      fichier: fileData,
      fichierContentType: fileContentType,
      eleves: mapIdList(selectedEleve),
      groupes: groupes.filter(group => selectedGroupe.includes(group.id)),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...devoirEntity,
          dateLimite: moment(devoirEntity.date),
          fichier: fileData,
          fichierContentType: fileContentType,
          profId: devoirEntity?.prof?.id,
          eleves: [],
        };

  const handleFileChange = ({ file }) => {
    if (file.status === 'done') {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result as string;
        setFileData(result.split(',')[1]); // Extract base64 data
        setFileContentType(file.type);
      };
      reader.readAsDataURL(file.originFileObj as Blob);
    }
  };

  const disabledDate = current => {
    return current && current < moment().startOf('day');
  };

  const handleSelectAll = checked => {
    const entities = isEleveMode ? eleves : groupes;
    const setSelected = isEleveMode ? setSelectedEleve : setSelectedGroupe;
    setSelected(checked ? entities.map(entity => entity.id) : []);
  };

  const handleFileRemove = () => {
    setFileData(undefined);
    setFileContentType(undefined);
  };
  const { Option } = Select;
  return (
    <div>
      <Row justify="center">
        <Col md={16}>
          <Tag bordered={false} style={{ padding: 15 }} className={'capitalize'} color="yellow">
            Créer un Devoir
          </Tag>
        </Col>
      </Row>
      <Row justify="center">
        <Col md={16}>
          {loading ? (
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          ) : (
            <Form
              name="basic"
              style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}
              initialValues={defaultValues()}
              onFinish={saveEntity}
              autoComplete="off"
            >
              <div style={{ backgroundColor: '#feffe6', padding: '20px', borderRadius: '5px' }}>
                <Form.Item name="dateLimite" rules={[{ required: true, message: 'Veuillez entrer la date limite !' }]}>
                  <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" disabledDate={disabledDate} />
                </Form.Item>
                <Form.Item>
                  <Tabs defaultActiveKey="eleve" onChange={key => setIsEleveMode(key === 'eleve')}>
                    <TabPane tab="Groupe" key="groupe">
                      <Checkbox onChange={e => handleSelectAll(e.target.checked)} checked={selectedGroupe.length === groupes.length}>
                        Tout sélectionner
                      </Checkbox>
                      <div
                        style={{ maxHeight: '200px', overflowY: 'auto', border: '1px solid #d9d9d9', borderRadius: '4px', padding: '8px' }}
                      >
                        <Checkbox.Group onChange={setSelectedGroupe} value={selectedGroupe}>
                          {groupes
                            .slice()
                            .sort((a, b) => a.nom.localeCompare(b.nom))
                            .map(entity => (
                              <Checkbox value={entity.id} key={entity.id}>
                                {entity.nom}
                              </Checkbox>
                            ))}
                        </Checkbox.Group>
                      </div>
                    </TabPane>

                    <TabPane tab="Élève" key="eleve">
                      <Checkbox onChange={e => handleSelectAll(e.target.checked)} checked={selectedEleve.length === eleves.length}>
                        Tout sélectionner
                      </Checkbox>
                      <div
                        style={{
                          maxHeight: '200px',
                          overflowY: 'auto',
                          border: '1px solid #d9d9d9',
                          borderRadius: '4px',
                          padding: '8px',
                        }}
                      >
                        <Checkbox.Group onChange={setSelectedEleve} value={selectedEleve}>
                          {eleves
                            .slice()
                            .sort((a, b) => a.nom.localeCompare(b.nom))
                            .map(entity => (
                              <Checkbox value={entity.id} key={entity.id}>
                                {`${entity.nom} ${entity.prenom}`}
                              </Checkbox>
                            ))}
                        </Checkbox.Group>
                      </div>
                    </TabPane>
                  </Tabs>
                </Form.Item>
              </div>
              <div style={{ backgroundColor: '#feffe6', padding: '20px', borderRadius: '5px' }}>
                <Form.Item name="titre" rules={[{ required: true, message: 'Veuillez entrer le titre !' }]}>
                  <Input placeholder="Titre" />
                </Form.Item>
                <Form.Item name="description">
                  <Input.TextArea placeholder="Description" style={{ height: '150px' }} />
                </Form.Item>
                <Form.Item name="profId">
                  <Select
                    placeholder="Selectionner un prof"
                    showSearch
                    filterOption={(input, option) => option.children.toString().includes(input)}
                  >
                    {profs.map(prof => (
                      <Option key={prof.id} value={prof.id}>
                        {prof?.firtName} {prof?.lastName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="fichier" hasFeedback>
                  <Upload
                    customRequest={({ onSuccess }) => onSuccess('ok')}
                    name="fichier"
                    showUploadList={false}
                    onChange={handleFileChange}
                    style={{ textAlign: 'center' }}
                  >
                    {fileData ? (
                      <div style={{ position: 'relative', display: 'inline-block' }}>
                        <span style={{ display: 'block', margin: '10px 0' }}>
                          Fichier sélectionné: {fileContentType}
                          <AntButton
                            style={{
                              position: 'relative',
                              top: 0,
                              right: 0,
                              background: 'red',
                              color: 'white',
                              border: 'none',
                              cursor: 'pointer',
                              fontSize: '16px',
                              padding: '2px',
                              lineHeight: '1',
                              marginLeft: '10px',
                            }}
                            icon={<PlusOutlined />}
                            onClick={e => {
                              e.stopPropagation(); // Prevent triggering file input click
                              handleFileRemove();
                            }}
                          >
                            &times;
                          </AntButton>
                        </span>
                      </div>
                    ) : (
                      <AntButton icon={<UploadOutlined />}>Sélectionner le fichier</AntButton>
                    )}
                  </Upload>
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <AntButton
                    type="default"
                    onClick={() => navigate('/devoir')}
                    style={{ marginRight: '10px' }}
                    icon={<ArrowLeftOutlined />}
                  >
                    Retour
                  </AntButton>
                  <AntButton type="primary" htmlType="submit" loading={updating} icon={<SaveOutlined />}>
                    Sauvegarder
                  </AntButton>
                </div>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DevoirUpdate;
