import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Alert, Button, List, Space, Tabs, Tag, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPlus, faSort, faSortDown, faSortUp, faSync, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities } from './emploi-temps.reducer';
import ShowEmploiTempsLigneModal from 'app/modules/modals/ShowEmploiTempsLigneModal';
import ShowEmploiTempsLigneTimeLineModal from 'app/modules/modals/ShowEmploiTempsLigneTimeLineModal';
import { GoLoading } from 'app/shared/loading';
import { getPaginationState, JhiPagination } from 'react-jhipster';
import { EmploiTempsType } from 'app/shared/model/enumerations/emploi-temps-type.model';
import { IEmploiTemps } from 'app/shared/model/emploi-temps.model';
import { Title } from 'chart.js';

const { TabPane } = Tabs;

export const EmploiTemps = () => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [emploiId, setEmploiId] = useState<number | undefined>();
  const [showModalTimeTable, setShowModalTimeTable] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const { Text, Title } = Typography;

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const handleCloseModal = () => {
    setShowModal(false);
    setShowModalTimeTable(false);
  };

  const handleOpenModal = (id: number) => {
    setEmploiId(id);
    setShowModal(true);
  };

  const handleOpenModalTimeTable = (id: number) => {
    setEmploiId(id);
    setShowModalTimeTable(true);
  };

  const emploiTempsList = useAppSelector(state => state.emploiTemps.entities);
  const loading = useAppSelector(state => state.emploiTemps.loading);
  const totalItems = useAppSelector(state => state.emploiTemps.totalItems);

  const getAllEntities = () => {
    const withRealAnneeScolaire = false;
    let query = `withRealAnneeScolaire=${withRealAnneeScolaire}`;
    if (selectedType) query += `&type.equals=${selectedType}`;
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: query,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, selectedType]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = (p: string) => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = (currentPage: number) =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h2 id="emploi-temps-heading" data-cy="EmploiTempsHeading">
        <Tag bordered={false} style={{ padding: 15 }} className={'capitalize'} color="orange">
          Emploi Temps
        </Tag>
        <div className="d-flex justify-content-end" style={{ marginTop: '3rem', marginBottom: '3rem' }}>
          <Button className="me-2" type="primary" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon={faSync} spin={loading} /> Actualiser la liste
          </Button>
          <Link to="/emploi-temps/new">
            <Button type="primary" icon={<FontAwesomeIcon icon={faPlus} />}>
              Créer un nouveau Emploi Temps
            </Button>
          </Link>
        </div>
      </h2>

      <Tabs defaultActiveKey="" onChange={setSelectedType}>
        <TabPane tab="tout" key="" />
        <TabPane tab="Groupe" key={EmploiTempsType.GROUPE} />
        <TabPane tab="Professeur" key={EmploiTempsType.PROF} />
      </Tabs>

      <div className="mt-4">
        {loading ? (
          <GoLoading loading={loading} />
        ) : emploiTempsList && emploiTempsList.length > 0 ? (
          <List
            grid={{
              gutter: 16,
              xs: 1, // 1 column on extra small screens (<576px)
              sm: 1, // 1 column on small screens (≥576px)
              md: 2, // 2 columns on medium screens (≥768px)
              lg: 2, // 2 columns on large screens (≥992px)
              xl: 2, // 2 columns on extra large screens (≥1200px)
            }}
            dataSource={emploiTempsList}
            renderItem={(item: IEmploiTemps) => {
              return (
                <List.Item
                  key={item.id}
                  className="hoverable-list-item"
                  onClick={() => handleOpenModalTimeTable(item.id)}
                  style={{
                    padding: '20px',
                    borderRadius: '8px',
                    border: '1px solid #e8e8e8',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#fff',
                    marginBottom: '16px',
                    cursor: 'pointer',
                  }}
                >
                  <Row justify="space-between" align="start">
                    <Col>
                      <Title level={5} type="secondary">
                        {item.id}
                      </Title>
                      <Title level={5}>{item.nom}</Title>
                    </Col>
                    <Col>
                      <Space size="small">
                        <Button
                          style={{ color: 'green' }}
                          type="link"
                          onClick={e => {
                            e.stopPropagation();
                            handleOpenModal(item.id);
                          }}
                          icon={<FontAwesomeIcon icon={faEye} />}
                        >
                          lignes
                        </Button>
                        <Button
                          type="link"
                          onClick={e => {
                            e.stopPropagation();
                            handleOpenModalTimeTable(item.id);
                          }}
                        >
                          <FontAwesomeIcon icon={faEye} /> <span className="d-none d-md-inline">Voir</span>
                        </Button>
                        <Button
                          danger
                          type="link"
                          onClick={e => {
                            e.stopPropagation();
                            window.location.href = `/emploi-temps/${item.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </List.Item>
              );
            }}
          />
        ) : (
          <Alert message="Aucun Matiere trouvé" type="warning" showIcon />
        )}
      </div>
      {showModal && <ShowEmploiTempsLigneModal emploiId={emploiId} show={showModal} handleClose={handleCloseModal} />}
      {showModalTimeTable && (
        <ShowEmploiTempsLigneTimeLineModal emploiId={emploiId} show={showModalTimeTable} handleClose={handleCloseModal} />
      )}
      {totalItems ? (
        <div className={emploiTempsList && emploiTempsList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex mt-4">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default EmploiTemps;
