import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, IEmploiTempsLigne } from 'app/shared/model/emploi-temps-ligne.model';

const initialState: EntityState<IEmploiTempsLigne> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export interface IDeleteEmploiTempsLigne {
  id?: number;
  idEmploi?: number;
}

const apiUrl = 'api/emploi-temps-lignes';

// Actions

export const getEmploiTempsLigne = createAsyncThunk(
  'emploiTempsLigne/fetch_entity_list',
  async ({ page, size, sort, query }: IQueryParams) => {
    const requestUrl = `${apiUrl}?${query}&${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
    return axios.get<IEmploiTempsLigne[]>(requestUrl);
  },
);

export const getEntity = createAsyncThunk(
  'emploiTempsLigne/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IEmploiTempsLigne>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'emploiTempsLigne/create_entity',
  async (entity: IEmploiTempsLigne, thunkAPI) => {
    const result = await axios.post<IEmploiTempsLigne>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(
      getEmploiTempsLigne({
        query: 'emploisTempsId.equals=' + entity.emploisTemps.id,
      }),
    );
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'emploiTempsLigne/update_entity',
  async (entity: IEmploiTempsLigne, thunkAPI) => {
    const result = await axios.put<IEmploiTempsLigne>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEmploiTempsLigne({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'emploiTempsLigne/partial_update_entity',
  async (entity: IEmploiTempsLigne, thunkAPI) => {
    const result = await axios.patch<IEmploiTempsLigne>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEmploiTempsLigne({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEmploiLigne = createAsyncThunk(
  'emploiTempsLigne/delete_entity',
  async (entity: IDeleteEmploiTempsLigne, thunkAPI) => {
    const requestUrl = `${apiUrl}/${entity.id}`;
    const result = await axios.delete<IEmploiTempsLigne>(requestUrl);
    thunkAPI.dispatch(getEmploiTempsLigne({}));
    thunkAPI.dispatch(
      getEmploiTempsLigne({
        query: 'emploisTempsId.equals=' + entity.idEmploi,
      }),
    );
    return result;
  },
  { serializeError: serializeAxiosError },
);

// slice

export const EmploiTempsLigneSlice = createEntitySlice({
  name: 'emploiTempsLigne',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEmploiLigne.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEmploiTempsLigne), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEmploiTempsLigne, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEmploiLigne), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = EmploiTempsLigneSlice.actions;

// Reducer
export default EmploiTempsLigneSlice.reducer;
