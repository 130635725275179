import React, { useEffect, useState } from 'react';
import { Modal, Timeline } from 'antd';
import { getEmploiTempsLigne } from 'app/entities/emploi-temps-ligne/emploi-temps-ligne.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { GoLoading } from 'app/shared/loading';
import './timetable.css';
import { IEmploiTempsLigne } from 'app/shared/model/emploi-temps-ligne.model';
import _ from 'lodash';
import { TimeTableInfo } from 'app/shared/timeTableInfo';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { getPaginationState } from 'react-jhipster';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

const ShowEmploiTempsLigneTimeLineModal = ({ show, handleClose, emploiId }) => {
  const emploiTempsLigneList = useAppSelector(state => state.emploiTempsLigne.entities) as IEmploiTempsLigne[];
  const loading = useAppSelector(state => state.emploiTempsLigne.loading);
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const sortedEmploiTempsLigneList = [...emploiTempsLigneList].sort((a, b) => {
    const timeA = dayjs(a.heureDebut).format('HH:mm:ss');
    const timeB = dayjs(b.heureDebut).format('HH:mm:ss');

    // Compare time strings
    return timeA.localeCompare(timeB);
  });

  let dictionary = _.groupBy(sortedEmploiTempsLigneList, function (b) {
    return b.jour;
  });

  const getAllEntities = () => {
    if (emploiId) {
      console.log('emploiId', emploiId);

      dispatch(
        getEmploiTempsLigne({
          query: 'emploisTempsId.equals=' + emploiId,
          size: 100,
          page: 0,
          sort: `heureDebut,asc`,
        }),
      );
    }
  };

  useEffect(() => {
    console.log('ShowEmploiTempsLigneTimeLineModal');
    getAllEntities();
  }, [emploiId]);

  return (
    <Modal width={'60%'} className={'time_table'} title="Emploi de temps" open={show} onOk={handleClose} onCancel={handleClose}>
      <GoLoading loading={loading} />
      <table>
        <thead>
          <tr>
            <th>Lundi</th>
            <th>Mardi</th>
            <th>Mercredi</th>
            <th>Jeudi</th>
            <th>Vendredi</th>
            <th>Samedi</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Timeline items={dictionary?.LUNDI?.map(value => ({ children: <TimeTableInfo data={value} /> }))} />
            </td>
            <td>
              <Timeline items={dictionary?.MARDI?.map(value => ({ children: <TimeTableInfo data={value} /> }))} />
            </td>
            <td>
              <Timeline items={dictionary?.MERCREDI?.map(value => ({ children: <TimeTableInfo data={value} /> }))} />
            </td>
            <td>
              <Timeline items={dictionary?.JEUDI?.map(value => ({ children: <TimeTableInfo data={value} /> }))} />
            </td>
            <td>
              <Timeline items={dictionary?.VENDREDI?.map(value => ({ children: <TimeTableInfo data={value} /> }))} />
            </td>
            <td>
              <Timeline items={dictionary?.SAMEDI?.map(value => ({ children: <TimeTableInfo data={value} /> }))} />
            </td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
};

export default ShowEmploiTempsLigneTimeLineModal;
