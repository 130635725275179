import dayjs from 'dayjs';
import { IGroupe } from 'app/shared/model/groupe.model';
import { IEleve } from 'app/shared/model/eleve.model';
import { INiveau } from 'app/shared/model/niveau.model';
import { IProf } from 'app/shared/model/prof.model';

export interface IDevoir {
  id?: number;
  titre?: string | null;
  description?: string | null;
  dateLimite?: dayjs.Dayjs | null;
  fichierContentType?: string | null;
  fichier?: string | null;
  niveau?: INiveau | null;
  groupe?: IGroupe | null;
  groupes?: IGroupe[] | null;
  eleves?: IEleve[] | null;
  prof?: IProf | null;
  profId?: number | null;
  valid?: boolean | null;
  profName?: string | null;
  groupeNames?: string | null;
}

export const defaultValue: Readonly<IDevoir> = {};
