import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEmploiTempsLigne as getEmploiTempsLignes } from 'app/entities/emploi-temps-ligne/emploi-temps-ligne.reducer';
import { getEntities as getProfs } from 'app/entities/prof/prof.reducer';
import { getEntities as getGroupes } from 'app/entities/groupe/groupe.reducer';
import { createEntity, getEntity, reset, updateEntity } from './matiere.reducer';

const { Title } = Typography;

const MatiereUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const emploiTempsLignes = useAppSelector(state => state.emploiTempsLigne.entities);
  const matiereEntity = useAppSelector(state => state.matiere.entity);
  const loading = useAppSelector(state => state.matiere.loading);
  const updating = useAppSelector(state => state.matiere.updating);
  const updateSuccess = useAppSelector(state => state.matiere.updateSuccess);

  const handleClose = () => {
    navigate('/matiere' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getEmploiTempsLignes({}));
    dispatch(getProfs({}));
    dispatch(getGroupes({}));
  }, [dispatch, id, isNew]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...matiereEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...matiereEntity,
        };

  return (
    <div>
      <Row justify="center">
        <Col span={12}>
          <Title level={2}>{isNew ? 'Créer une Matière' : 'Éditer une Matière'}</Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={12}>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <Form initialValues={defaultValues()} onFinish={saveEntity} layout="vertical">
              {!isNew && (
                <Form.Item name="id" label="Numéro" rules={[{ required: true, message: 'Le numéro est requis' }]}>
                  <Input disabled />
                </Form.Item>
              )}
              <Form.Item name="nom" label="Nom" rules={[{ required: true, message: 'Le nom est requis' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="code" label="Code" rules={[{ required: true, message: 'Le code est requis' }]}>
                <Input />
              </Form.Item>
              <Form.Item>
                <Button type="default" onClick={() => navigate('/matiere')}>
                  Retour
                </Button>
                <Button type="primary" htmlType="submit" style={{ marginLeft: '8px' }} loading={updating}>
                  Sauvegarder
                </Button>
              </Form.Item>
            </Form>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default MatiereUpdate;
