import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { byteSize, openFile, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getDevoir } from './devoir.reducer';

export const DevoirDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getDevoir(id));
  }, []);

  const devoirEntity = useAppSelector(state => state.devoir.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="devoirDetailsHeading">Devoir</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{devoirEntity.id}</dd>
          <dt>
            <span id="titre">Titre</span>
          </dt>
          <dd>{devoirEntity.titre}</dd>
          <dt>
            <span id="description">Description</span>
          </dt>
          <dd>{devoirEntity.description}</dd>
          <dt>
            <span id="dateLimite">Date Limite</span>
          </dt>
          <dd>
            {devoirEntity.dateLimite ? <TextFormat value={devoirEntity.dateLimite} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="fichier">Fichier</span>
          </dt>
          <dd>
            {devoirEntity.fichier ? (
              <div>
                {devoirEntity.fichierContentType ? (
                  <a onClick={openFile(devoirEntity.fichierContentType, devoirEntity.fichier)}>Ouvrir&nbsp;</a>
                ) : null}
                <span>
                  {devoirEntity.fichierContentType}, {byteSize(devoirEntity.fichier)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <span id="groupe">Groupe</span>
          </dt>
          <dd>{devoirEntity.groupe ? devoirEntity.groupe.nom : ''}</dd>
          <dt>Eleve</dt>
          <dd>
            {devoirEntity.eleves
              ? devoirEntity.eleves.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.nom}</a>
                    {devoirEntity.eleves && i === devoirEntity.eleves.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/devoir" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Retour</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/devoir/${devoirEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Editer</span>
        </Button>
      </Col>
    </Row>
  );
};

export default DevoirDetail;
