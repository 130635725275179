import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getDevoir, getEntities as getDevoirs, validateDevoir } from './devoir.reducer';
import { getNiveauEntities as getNiveaux } from 'app/entities/niveau/niveau.reducer';
import { getEntities as getGroupes } from 'app/entities/groupe/groupe.reducer';
import { getEntities as getEleves } from 'app/entities/eleve/eleve.reducer';
import {
  Alert,
  Badge,
  Button,
  Col,
  DatePicker,
  List,
  message,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEdit, faExchange, faPlus, faSync, faTrash } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { getPaginationState } from 'react-jhipster';
import { ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { GoLoading } from 'app/shared/loading';
import { IDevoir } from 'app/shared/model/devoir.model';
import 'dayjs/locale/fr';
import relativeTime from 'dayjs/plugin/relativeTime';
import '../garderie/styling.scss';
import { downloadFile } from 'app/shared/util/utilitaire';
import { getEntities as getProfs } from 'app/entities/prof/prof.reducer';
import { IProf } from 'app/shared/model/prof.model';

dayjs.extend(relativeTime);
dayjs.locale('fr');
const { Option } = Select;

const Devoir = () => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;
  const { Text, Title } = Typography;
  const [paginationState, setPaginationState] = useState(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'dateLimite', 'desc'));
  const [selectedEleve, setSelectedEleve] = useState('');
  const [selectedDateDebut, setSelectedDateDebut] = useState(null);
  const [selectedDateFin, setSelectedDateFin] = useState(null);
  const [selectedNiveau, setSelectedNiveau] = useState(null);
  const [selectedGroupe, setSelectedGroupe] = useState(null);
  const [selectedTitre, setSelectedTitre] = useState(null);
  const [selectedProf, setSelectedProf] = useState(null);
  const [validDeVoir, setValidDeVoir] = useState(false);
  const [donwload, setDownload] = useState(false);

  const devoirList = useAppSelector(state => state.devoir.entities);
  const devoir = useAppSelector(state => state.devoir.entity);

  const loading = useAppSelector(state => state.devoir.loading);
  const totalItems = useAppSelector(state => state.devoir.totalItems);

  const niveaux = useAppSelector(state => state.niveau.entities);
  const groupes = useAppSelector(state => state.groupe.entities);
  const eleves = useAppSelector(state => state.eleve.entities);
  const profs = useAppSelector(state => state.prof.entities) as IProf[];

  useEffect(() => {
    dispatch(
      getNiveaux({
        page: 0,
        size: 100,
        sort: 'id,desc',
        query: '',
      }),
    );
    dispatch(
      getGroupes({
        page: 0,
        size: 100,
        sort: 'id,desc',
        query: '',
      }),
    );
    dispatch(getEleves({}));
    dispatch(
      getProfs({
        page: 0,
        size: 100,
        sort: 'id,desc',
        query: '',
      }),
    );
  }, []);

  useEffect(() => {
    const withRealAnneeScolaire = false;

    const queryParams = [];
    queryParams.push(`valid.equals=${validDeVoir}`);

    if (selectedEleve) {
      queryParams.push(`eleveId.equals=${selectedEleve}`);
    }

    if (selectedTitre) {
      queryParams.push(`titre.equals=${selectedTitre}`);
    }

    if (selectedNiveau) {
      queryParams.push(`niveauId.equals=${selectedNiveau}`);
    }

    if (selectedGroupe) {
      queryParams.push(`groupeId.equals=${selectedGroupe}`);
    }

    if (selectedProf) {
      queryParams.push(`profId.equals=${selectedProf}`);
    }

    if (selectedDateDebut) {
      const formattedDateDebut = selectedDateDebut.format('YYYY-MM-DD');
      queryParams.push(`dateDebut.greaterThanOrEqual=${formattedDateDebut}`);
    }

    if (selectedDateFin) {
      const formattedDateFin = selectedDateFin.format('YYYY-MM-DD');
      queryParams.push(`dateFin.lessThanOrEqual=${formattedDateFin}`);
    }

    queryParams.push(`withRealAnneeScolaire=${withRealAnneeScolaire}`);

    const query = queryParams.join('&');

    dispatch(
      getDevoirs({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      }),
    );
  }, [
    validDeVoir,
    selectedEleve,
    selectedTitre,
    selectedProf,
    selectedNiveau,
    selectedGroupe,
    selectedDateDebut,
    selectedDateFin,
    paginationState,
  ]);

  const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);
    setValidDeVoir(checked);
  };

  const validate = (devoirId: number) => {
    dispatch(validateDevoir(devoirId));
    console.log(`validateDevoir  ${devoirId}`);
    message.success('Click on Yes');
  };

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  useEffect(() => {
    if (devoir && devoir?.id && donwload) {
      console.log('hellowd', devoir);
      const blobString = devoir?.fichier; // base64 encoded string
      const fileType = devoir?.fichierContentType; // or any other mime type
      const fileName = `${devoir?.titre}_content`;

      downloadFile(blobString, fileType, fileName);
      setDownload(false);
    }
  }, [devoir]);

  const sortEntities = () => {
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const handlePaginationChange = (currentPage: number) => {
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });
  };

  const handleEleveChange = (value: string) => {
    setSelectedEleve(value);
  };

  return (
    <div
      className="container"
      style={{
        backgroundColor: validDeVoir ? 'lightblue' : '',
      }}
    >
      <Tag bordered={false} style={{ padding: 15 }} className={'capitalize'} color={validDeVoir ? 'processing' : 'warning'}>
        {validDeVoir ? 'Devoir valide' : 'Devoir non valide'}
      </Tag>
      <Switch defaultChecked value={validDeVoir} onChange={onChange} />
      <div className="d-flex justify-content-end mb-3">
        <Button className="me-2" icon={<FontAwesomeIcon icon={faSync} spin={loading} />} onClick={handleSyncList} loading={loading}>
          Actualiser la liste
        </Button>
        <Link to="/devoir/new">
          <Button type="primary" icon={<FontAwesomeIcon icon={faPlus} />}>
            Créer un nouveau Devoir
          </Button>
        </Link>
      </div>

      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} sm={12} md={8}>
          <Select
            allowClear={true}
            placeholder="Prof"
            onChange={setSelectedProf}
            showSearch
            filterOption={(input, option) => option.children.toString().includes(input)}
            style={{ width: '100%', fontWeight: 'lighter' }}
          >
            {profs.map(prof => (
              <Option key={prof.id} value={prof.id}>
                {prof?.firtName} {prof?.lastName}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Select
            showSearch
            // value={selectedEleve}
            onChange={handleEleveChange}
            placeholder="élève"
            style={{ width: '100%', fontWeight: 'lighter' }}
            allowClear
            filterOption={(input, option) => {
              const text = option?.label || option?.children;
              return text?.toString().toLowerCase().includes(input.toLowerCase());
            }}
          >
            {eleves.map(eleve => (
              <Option key={eleve.id} value={eleve.id}>
                {eleve.nom} {eleve.prenom}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Select
            allowClear={true}
            placeholder="Niveau"
            showSearch
            filterOption={(input, option) => option.children.toString().includes(input)}
            onChange={setSelectedNiveau}
            style={{ width: '100%', fontWeight: 'lighter' }}
          >
            {niveaux.map(niveau => (
              <Option key={niveau.id} value={niveau.id}>
                {niveau.libelle}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Select
            allowClear={true}
            placeholder="Groupe"
            showSearch
            filterOption={(input, option) => option.children.toString().includes(input)}
            onChange={setSelectedGroupe}
            style={{ width: '100%', fontWeight: 'lighter' }}
          >
            {groupes.map(groupe => (
              <Option key={groupe.id} value={groupe.id}>
                {groupe.nom}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <DatePicker placeholder="Select Date de Début" onChange={setSelectedDateDebut} style={{ width: '100%', fontWeight: 'lighter' }} />
        </Col>

        <Col xs={24} sm={12} md={8}>
          <DatePicker placeholder="Select Date de Fin" onChange={setSelectedDateFin} style={{ width: '100%', fontWeight: 'lighter' }} />
        </Col>
      </Row>
      <div className="mt-4">
        {loading ? (
          <GoLoading loading={loading} />
        ) : devoirList && devoirList.length > 0 ? (
          <List
            grid={{
              gutter: 16,
              xs: 1, // 2 columns on extra small screens (<576px)
              sm: 1, // 2 columns on small screens (≥576px)
              md: 2, // 2 columns on medium screens (≥768px)
              lg: 2, // 2 columns on large screens (≥992px)
              xl: 2, // 2 columns on extra large screens (≥1200px)
              xxl: 3, // 2
            }}
            dataSource={devoirList}
            renderItem={(item: IDevoir) => {
              const isDeadlinePassed = dayjs().isAfter(item?.dateLimite);
              return (
                <Badge.Ribbon placement="end" text={item.profName}>
                  <List.Item
                    key={item.id}
                    className="hoverable-list-item"
                    onClick={() => navigate(`/devoir/${item.id}`)}
                    style={{
                      padding: '20px',
                      borderRadius: '8px',
                      border: '1px solid #e8e8e8',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      backgroundColor: '#fff',
                      marginBottom: '16px',
                      cursor: 'pointer',
                    }}
                  >
                    <Row justify="space-between" align="middle">
                      <Col>
                        <Badge
                          count={isDeadlinePassed ? 'Passé' : 'En cours'}
                          style={{
                            backgroundColor: isDeadlinePassed ? '#f5222d' : '#52c41a',
                            color: '#fff',
                            marginBottom: '8px',
                          }}
                        />
                        <Title level={5}>{dayjs(item.dateLimite).format('DD MMM, YYYY')}</Title>
                        <Text strong>
                          <Text style={{ color: '#1DA57A', marginRight: 6 }}>{item?.titre}</Text>
                        </Text>
                        <Text type="secondary">{dayjs(item.dateLimite).fromNow()}</Text>
                        <p style={{ marginTop: '8px' }}>
                          {item?.description?.length > 25 ? `${item.description.substring(0, 35)}...` : item.description}
                        </p>
                      </Col>
                      <Col>
                        <Space size="small">
                          <Popconfirm
                            title="Validation de devoir"
                            description={
                              validDeVoir
                                ? 'Êtes-vous sûr de vouloir désactiver ce devoir?'
                                : 'Êtes-vous sûr de vouloir valider ce devoir ?'
                            }
                            onConfirm={e => {
                              e.stopPropagation();
                              validate(item?.id);
                            }}
                            onCancel={() => {}}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Tooltip color={'orange'} title={validDeVoir ? 'Désactiver ce devoir' : 'Valider ce devoir '}>
                              <Button
                                style={{ color: 'orange' }}
                                onClick={e => {
                                  e.stopPropagation();
                                }}
                                type="link"
                                icon={<FontAwesomeIcon icon={faExchange} />}
                              />
                            </Tooltip>
                          </Popconfirm>
                          <Tooltip color={'blue'} title="Télecharger">
                            <Button
                              onClick={e => {
                                setDownload(true);
                                e.stopPropagation();
                                dispatch(getDevoir(item.id));
                              }}
                              type="link"
                              disabled={!item?.fichierContentType}
                              icon={<FontAwesomeIcon icon={faDownload} />}
                            />
                          </Tooltip>
                          <Tooltip color={'green'} title="Modifier">
                            <Button
                              onClick={e => {
                                e.stopPropagation();
                                navigate(`/devoir/${item.id}/edit`);
                              }}
                              type="link"
                              icon={<FontAwesomeIcon icon={faEdit} />}
                              style={{ color: 'green' }}
                            />
                          </Tooltip>
                          <Tooltip color={'red'} title="Supprimer">
                            <Button
                              onClick={e => {
                                e.stopPropagation();
                                navigate(`/devoir/${item.id}/delete`);
                              }}
                              type="link"
                              danger
                              icon={<FontAwesomeIcon icon={faTrash} />}
                            />
                          </Tooltip>
                        </Space>
                      </Col>
                    </Row>

                    <Row>
                      {item.groupeNames ? (
                        item.groupeNames.split(',').map(value => (
                          <Tag bordered={false} color="processing">
                            {value}
                          </Tag>
                        ))
                      ) : (
                        <></>
                      )}
                    </Row>
                  </List.Item>
                </Badge.Ribbon>
              );
            }}
          />
        ) : (
          <Alert message="Aucun Devoir trouvé" type="warning" />
        )}
      </div>
      {!loading && totalItems && devoirList && devoirList.length > 0 && (
        <div className="d-flex justify-content-center mt-4">
          <Pagination
            current={paginationState.activePage}
            onChange={handlePaginationChange}
            total={totalItems}
            pageSize={paginationState.itemsPerPage}
            showSizeChanger={false}
          />
        </div>
      )}
    </div>
  );
};

export default Devoir;
